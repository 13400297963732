import * as React from "react"
import { Link } from "gatsby"
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import SEO from "../Components/Seo/seo";

// markup
const NotFoundPage = () => {
  return (
    <>
    <Header/>
    <SEO title="404: Not found" />
    <div className="not-found-page">
    <h1>Ooops!</h1>
    <p>We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
    <div className="btnfof">
    <Link to="/contact" className="btn btn-outline btnproperty">Contact Us</Link>
	  <Link to="/" className="btn btn-outline btnproperty">Homepage</Link>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default NotFoundPage
